import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import Svg, { Path } from 'react-native-svg'

const SpeechBubble = ({ children }: { children: React.ReactNode }) => {
  return (
    <View style={{ width: 300, height: 300, position: 'absolute', top: 100 }}>
      <Svg height="100%" width="100%" viewBox="0 0 300 300">
        <Path
          stroke="black"
          strokeWidth={5}
          fill="white"
          d="M253 177.5C255.667 191.5 244.6 221.7 229 240.5C234.804 218.558 250.075 208.468 229 170.5C213.787 175.329 205.852 175.655 196 159C159.36 198.276 146.21 186.764 119.5 181C91.8324 198.021 76.6233 204.161 52 187C9.74418 182.002 5.73997 172.264 12.5 150C-9.39958 106.849 2.947 96.7796 34 84C22.5755 38.7657 31.4193 26.2338 74 26.5C114.579 -4.84676 143.875 -5.66448 200 15.5C245.306 0.664572 260.284 33.4643 271 72C291.964 104.046 291.314 120.898 279.5 150C274.771 161.839 271.713 168.397 253 177.5Z"
        />
      </Svg>
      <View
        style={{
          position: 'absolute',
          left: 50,
          right: 50,
          top: 50,
          height: 100,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Text style={{ fontSize: 30, textAlign: 'center' }}>{children}</Text>
      </View>
    </View>
  )
}

export default SpeechBubble

const styles = StyleSheet.create({})
