import AsyncStorage from '@react-native-async-storage/async-storage'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View, TextInput, Button } from 'react-native'
import { RootStackParamList } from '../../App'
import BrandView from '../components/BrandView'
import Card from '../components/Card'
import ClearButton from '../components/ClearButton'
import CopyView from '../components/CopyView'
import ErrorText from '../components/ErrorText'
import { useAuth } from '../hooks'

type Props = NativeStackScreenProps<RootStackParamList, 'Login'>

export default function Login({ navigation }: Props) {
  const [_hasToken, setHasToken] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { login, refreshToken } = useAuth()
  const [loginTextStyle, setLoginTextStyle] = useState(styles.input)

  const { t } = useTranslation()

  useEffect(() => {
    AsyncStorage.getItem('token').then(async token => {
      if (!token) {
        return
      }
      setHasToken(true)
      await refreshToken.mutateAsync({ token })

      if (refreshToken.isError) {
        setHasToken(false)
        return
      }
      navigation.reset({ routes: [{ name: 'Home' }] })
    })
  }, [])

  async function submitHandler() {
    AsyncStorage.setItem('resetLoginData', email)
    if (email.match(/\d{8}/)) {
      login.mutate({ phone: email, password })
      return
    } else if (email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)) {
      login.mutate({ email, password })
      return
    }
  }

  async function validateLoginTextInput(v: string) {
    setEmail(v)
    if (
      v.match(/\d{8}/) ||
      v.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)
    ) {
      setLoginTextStyle(styles.validLoginInput)
      return
    }
    if (v.length) {
      setLoginTextStyle(styles.invalidLoginInput)
      return
    }
    setLoginTextStyle(styles.input)
  }

  return (
    <View style={styles.container}>
      <Card isLoading={login.isPending || refreshToken.isPending}>
        <BrandView />
        <View style={styles.form}>
          <TextInput
            style={loginTextStyle}
            value={email}
            placeholder={t('auth.email')}
            placeholderTextColor="#AAAAAA"
            autoCorrect={false}
            onChangeText={validateLoginTextInput}
          />
          <TextInput
            style={styles.input}
            value={password}
            placeholder={t('auth.password')}
            placeholderTextColor="#AAAAAA"
            secureTextEntry
            autoCorrect={false}
            onChangeText={setPassword}
          />
          <ErrorText>{login.isError && t('auth.error.login')}</ErrorText>
          <Button title={t('auth.sign_in')} onPress={submitHandler} />
          <View
            style={{
              flexBasis: 26,
              marginTop: 28,
              marginBottom: 12,
              justifyContent: 'center',
            }}
          >
            <ClearButton onPress={() => navigation.navigate('Register')}>
              {t('auth.to_register')}
            </ClearButton>
            <View style={{ height: 12 }} />
            <ClearButton
              onPress={() => navigation.navigate('ResetPasswordForm')}
            >
              {t('auth.to_reset')}
            </ClearButton>
          </View>
        </View>
        <CopyView />
      </Card>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#2E2E2E',
    alignItems: 'stretch',
    justifyContent: 'space-around',
  },
  form: {
    marginVertical: 18,
  },
  input: {
    height: 44,
    padding: 12,
    marginVertical: 4,
    borderStyle: 'solid',
    borderWidth: 1.2,
    borderRadius: 5,
    borderColor: '#686868',
    color: '#AAAAAA',
  },
  validLoginInput: {
    height: 44,
    padding: 12,
    marginVertical: 4,
    borderStyle: 'solid',
    borderWidth: 1.2,
    borderRadius: 5,
    borderColor: '#44AA44',
    color: '#AAAAAA',
  },
  invalidLoginInput: {
    height: 44,
    padding: 12,
    marginVertical: 4,
    borderStyle: 'solid',
    borderWidth: 1.2,
    borderRadius: 5,
    borderColor: '#FF0404',
    color: '#AAAAAA',
  },
})
