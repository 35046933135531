import { useTranslation } from 'react-i18next'
import { Button, StyleSheet, View } from 'react-native'
import { Gate } from '../types'
import PrimaryText from './PrimaryText'

const GatePicker = ({
  gates,
  onSelect,
}: {
  gates: Gate[]
  onSelect(gate: Gate): void
}) => {
  const { t } = useTranslation()
  return (
    <>
      <PrimaryText>{t('gate.choose')}</PrimaryText>

      <View style={{ maxHeight: 400, marginTop: 10 }}>
        {gates.map(gate => (
          <View key={gate._id} style={{ marginTop: 8 }}>
            <Button
              key={gate._id}
              title={gate.name}
              disabled={gate.status === 'OPENED'}
              onPress={() => onSelect(gate)}
            />
          </View>
        ))}
      </View>
    </>
  )
}

export default GatePicker

const styles = StyleSheet.create({
  li: {
    borderRadius: 3,
    backgroundColor: '#303030',
    display: 'flex',
    marginTop: 5,
    padding: 12,
    maxWidth: 240,
  },
})
