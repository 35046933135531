import axios from 'axios'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import { useMutation } from '@tanstack/react-query'
import { RootStackParamList } from '../../App'
import { AuthResponse } from '../types'

export const client = axios.create({
  // baseURL: Constants.expoConfig?.extra?.baseURL,
  baseURL: 'https://api.mergensavdag.com/api/v1',
  // baseURL: 'http://localhost:4000/api/v1',
})
console.log(client.defaults.baseURL)

export function useAuth() {
  const navigation = useNavigation<NavigationProp<RootStackParamList>>()
  const login = useMutation({
    mutationKey: ['auth', 'login'],
    async mutationFn(
      credentials: { password: string } & (
        | { phone: string }
        | { email: string }
      )
    ) {
      const res = await client.post<AuthResponse>('/login', credentials)
      return res.data
    },
    onSuccess(data) {
      client.defaults.headers.common.Authorization = `Bearer ${data.token}`
      AsyncStorage.setItem('token', data.token)
      data.user.addressId &&
        AsyncStorage.setItem('address', data.user.addressId)
      navigation.reset({ routes: [{ name: 'Home' }] })
    },
  })
  const refreshToken = useMutation({
    mutationKey: ['auth', 'me'],
    async mutationFn({ token }: { token: string }) {
      client.defaults.headers.common.Authorization = `Bearer ${token}`
      const res = await client.post<AuthResponse>('/me')
      return res.data
    },
    onSuccess(data) {
      client.defaults.headers.common.Authorization = `Bearer ${data.token}`
      AsyncStorage.setItem('token', data.token)

      data.user.addressId &&
        AsyncStorage.setItem('address', data.user.addressId)
    },
    onError() {
      AsyncStorage.clear()
      navigation.reset({ routes: [{ name: 'Login' }] })
    },
  })
  const register = useMutation({
    mutationKey: ['auth', 'register'],
    async mutationFn(
      credentials: {
        name: string
        password: string
      } & ({ phone: string } | { email: string })
    ) {
      const res = await client.post<{ codeStr: string }>(
        '/register',
        credentials
      )
      return res.data
    },
    onSuccess(data) {
      navigation.reset({ routes: [{ name: 'Confirm' }] })
      AsyncStorage.setItem('codeStr', data.codeStr)
    },
  })
  const confirm = useMutation({
    mutationKey: ['auth', 'confirm'],
    async mutationFn({ code }: { code: string }) {
      const codeStr = await AsyncStorage.getItem('codeStr')
      const res = await client.get(`/verify/${codeStr}/${code}`)
      return res.data
    },
    onSuccess() {
      navigation.reset({ routes: [{ name: 'Login' }] })
    },
  })
  const resetPassword = useMutation({
    mutationKey: ['auth', 'confirm'],
    async mutationFn(payload: { phone: string } | { email: string }) {
      const res = await client.post(`/users/reset-password`, payload)
      return res.data
    },
    onSuccess() {
      navigation.reset({ routes: [{ name: 'ResetPasswordVerify' }] })
    },
  })
  const verifyResetPassword = useMutation({
    mutationKey: ['auth', 'confirm'],
    async mutationFn(
      payload: { code: string } & ({ phone: string } | { email: string })
    ) {
      const res = await client.post(`/users/reset-password-verify`, payload)
      AsyncStorage.setItem('passwordResetToken', res.data.resetToken)
      return res.data
    },
    onSuccess() {
      navigation.reset({ routes: [{ name: 'ResetPasswordConfirm' }] })
    },
  })
  const confirmResetPassword = useMutation({
    mutationKey: ['auth', 'confirm'],
    async mutationFn(
      payload: {
        resetToken: string
        password: string
      } & ({ phone: string } | { email: string })
    ) {
      const res = await client.post(`/users/reset-password-confirm`, payload)
      return res.data
    },
    onSuccess() {
      navigation.reset({ routes: [{ name: 'Login' }] })
    },
  })
  const logout = () => {
    AsyncStorage.clear()
    delete client.defaults.headers.common.Authorization
    navigation.reset({ routes: [{ name: 'Login' }] })
  }
  return {
    login,
    refreshToken,
    register,
    confirm,
    resetPassword,
    verifyResetPassword,
    confirmResetPassword,
    logout,
  }
}
