import { View, StyleSheet, ViewStyle, ActivityIndicator } from 'react-native'

export default function Card({
  children,
  style,
  isLoading,
}: {
  children: React.ReactNode
  style?: ViewStyle
  isLoading?: boolean
}) {
  return (
    <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
      <View style={[styles.card, style, { opacity: isLoading ? 0.6 : 1 }]}>
        {children}

        {isLoading && (
          <View style={{ position: 'absolute', width: '100%', height: '100%' }}>
            <ActivityIndicator size="large" />
          </View>
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  card: {
    flexGrow: 1,
    maxWidth: 400,
    marginHorizontal: 12,
    padding: 12,
    borderRadius: 5,
    flexShrink: 1,
    backgroundColor: '#404040',
    alignItems: 'stretch',
    justifyContent: 'space-evenly',
  },
})
