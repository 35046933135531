import { useTranslation } from 'react-i18next'
import { FlatList, StyleSheet, TouchableOpacity, View } from 'react-native'
import { Address } from '../types'
import PrimaryText from './PrimaryText'
import SecondaryText from './SecondaryText'

const AddressPicker = ({
  addresses,
  onSelect,
}: {
  addresses: Address[]
  onSelect(address: Address): void
}) => {
  const { t } = useTranslation()
  return (
    <>
      <PrimaryText>{t('address.choose')}</PrimaryText>

      <View style={{ maxHeight: 400, marginTop: 10 }}>
        <FlatList
          data={addresses}
          keyExtractor={item => item._id}
          renderItem={({ item }) => (
            <TouchableOpacity onPress={() => onSelect(item)}>
              <View style={styles.li}>
                <PrimaryText>{item.name}</PrimaryText>
                <SecondaryText>
                  {item.district} {item.subDistrict} {item.street}{' '}
                  {item.buildings}
                </SecondaryText>
              </View>
            </TouchableOpacity>
          )}
        />
      </View>
    </>
  )
}

export default AddressPicker

const styles = StyleSheet.create({
  li: {
    borderRadius: 3,
    backgroundColor: '#303030',
    display: 'flex',
    marginTop: 5,
    padding: 12,
    maxWidth: 240,
  },
})
