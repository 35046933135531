import { StyleSheet, TextInput, View } from 'react-native'
import React, { useRef } from 'react'

interface Props {
  value: string
  onChange?(value: string): void
  onFill?(value: string): void
  onPaste?(value: string): void
  isDisabled?: boolean
}

const length = 6
const CodeInput = ({ value, onChange, onFill, isDisabled }: Props) => {
  const inputs = useRef<(TextInput | null)[]>([])

  function push(v: string) {
    inputs.current[Math.min(value.length + 1, 5)]?.focus()
    onChange && onChange(value + v)
    if (value.length + 1 === length && onFill) onFill(value + v)
  }

  function pop() {
    onChange && onChange(value.slice(0, value.length - 1))
    inputs.current[Math.max(value.length - 1, 0)]?.focus()
    // onChange && onChange(value.slice(0, i) + value.slice(i + 1, value.length))
  }

  return (
    <View style={styles.code}>
      {new Array(length).fill(0, 0, length).map((_, i) => {
        return (
          <TextInput
            ref={component => (inputs.current[i] = component)}
            key={i}
            style={styles.input}
            value={value[i]}
            maxLength={6}
            autoCorrect={false}
            keyboardType="numeric"
            placeholderTextColor="#AAAAAA"
            editable={!isDisabled}
            // onFocus={() =>
            //   inputs.current[
            //     Math.min(Math.max(value.length, 0), length - 1)
            //   ]?.focus()
            // }
            onKeyPress={e => {
              const key = e.nativeEvent.key
              if (key === 'Backspace') pop()
              if (key >= '0' && key <= '9') push(key)
            }}
            onChangeText={v => {
              console.log(`[CODE INTPUT] ${v}`)
              if (RegExp('[0-9]{6}').test(v)) {
                value = v
                onChange && onChange(v)
                onFill && onFill(v)
              console.log(`[CODE INTPUT2] ${v}`)
              }
            }}
          />
        )
      })}
    </View>
  )
}

export default CodeInput

const styles = StyleSheet.create({
  code: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  input: {
    height: 44,
    width: 35,
    marginHorizontal: 10,
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderStyle: 'solid',
    borderWidth: 1.2,
    borderRadius: 5,
    borderColor: '#686868',
    fontSize: 24,
    color: '#FFFFFF',
  },
})
