import AsyncStorage from '@react-native-async-storage/async-storage'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { Address } from '../types'
import { client } from './useAuth'

export function useAddresses() {
  const [address, setAddress] = useState<string>()
  const queryClient = useQueryClient()

  !address && AsyncStorage.getItem('address').then(v => v && setAddress(v))

  const pick = useMutation({
    mutationKey: ['addresses', 'pick'],
    async mutationFn({ _id }: { _id: string }) {
      const res = await client.post('/addresses/pick/' + _id)
      return res.data
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['gates'] })
    },
  })

  const addresses = useQuery({
    queryKey: ['addresses'],
    async queryFn() {
      const res = await client.get<{
        items: Address[]
        total: number
        page: number
      }>('/addresses')
      return res.data.items
    },
    initialData: [],
  })
  return {
    pick,
    addresses,
    address,
  }
}
