import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View, Text } from 'react-native'
import OTPInput from '@twotalltotems/react-native-otp-input'

import { RootStackParamList } from '../../App'
import BrandView from '../components/BrandView'
import Card from '../components/Card'
import ClearButton from '../components/ClearButton'
import CodeInput from '../components/CodeInput'
import CopyView from '../components/CopyView'
import ErrorText from '../components/ErrorText'
import { useAuth } from '../hooks'

type Props = NativeStackScreenProps<RootStackParamList, 'Confirm'>

export default function Login({ navigation }: Props) {
  const [code, setCode] = useState('')
  const { confirm } = useAuth()
  const { t } = useTranslation()

  useEffect(() => setCode(''), [confirm.isError])

  function submitHandler(value: string) {
    //TODO validation
    confirm.mutate({ code: value })
  }

  return (
    <View style={styles.container}>
      <Card isLoading={confirm.isPending}>
        <BrandView />
        <View style={{ alignItems: 'center', marginBottom: 12 }}>
          <Text style={styles.title}>{t('auth.check_email')}</Text>
        </View>

        {/* <OTPInput
          pinCount={6}
          autoFocusOnLoad          
          onCodeChanged={setCode}
          editable={confirm.isLoading}
          code={code}
          onCodeFilled={submitHandler}
        /> */}
        <CodeInput
          value={code}
          isDisabled={confirm.isPending}
          onChange={setCode}
          onFill={submitHandler}
        />
        <ErrorText>{confirm.isError && t('auth.error.confirm')}</ErrorText>
        <View
          style={{
            flexBasis: 26,
            marginTop: 12,
            justifyContent: 'center',
          }}
        >
          <ClearButton onPress={() => navigation.navigate('Login')}>
            {t('auth.to_login')}
          </ClearButton>
        </View>
        <CopyView />
      </Card>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#2E2E2E',
    alignItems: 'stretch',
    justifyContent: 'space-around',
  },
  form: {
    marginVertical: 18,
  },
  title: {
    fontSize: 14,
    color: '#FFFFFF',
  },

  borderStyleBase: {
    width: 30,
    height: 45
  },
  borderStyleHighLighted: {
    borderColor: "#03DAC6",
  },
  underlineStyleBase: {
    width: 30,
    height: 45,
    borderWidth: 0,
    borderBottomWidth: 1,
  },
  underlineStyleHighLighted: {
    borderColor: "#03DAC6",
  },
})
