import { useTranslation } from 'react-i18next'
import { View, Image, Text, StyleSheet } from 'react-native'

export default function BrandView() {
  const { t } = useTranslation()
  return (
    <>
      <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
        <View style={styles.logo}>
          <Image
            style={styles.image}
            source={require('../../assets/banner.png')}
          />
        </View>
      </View>
      <View style={styles.titleContainer}>
        <Text style={styles.title}>{t('brand.title')}</Text>
        <Text style={styles.description}>{t('brand.description')}</Text>
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  logo: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    height: 162,
    aspectRatio: 1024 / 500
  },
  titleContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
  },
  title: {
    fontSize: 14,
    color: '#FFFFFF',
  },
  description: {
    marginTop: 3,
    fontSize: 12,
    color: '#AAAAAA',
  },
})
