import { Pressable, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import Svg, { Path } from 'react-native-svg'
import { useAuth } from '../hooks'

const LogOut = () => {
  const { logout } = useAuth()
  return (
    <Pressable onPress={logout}>
      <View style={{ width: 24, height: 24 }}>
        <Svg
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          data-name="Flat Line"
        >
          <Path
            d="M18 9L21 12 18 15"
            fill="none"
            stroke="rgb(180, 180, 180)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
          />
          <Path
            data-name="primary"
            d="M21 12L7 12"
            fill="none"
            stroke="rgb(180, 180, 180)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
          />
          <Path
            data-name="primary"
            d="M7 4H4a1 1 0 00-1 1v14a1 1 0 001 1h3"
            fill="none"
            stroke="rgb(180, 180, 180)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
          />
        </Svg>
      </View>
    </Pressable>
  )
}

export default LogOut
