import { StyleSheet, Text, TextProps, View } from 'react-native'
import React from 'react'

const ErrorText = (props: TextProps) => {
  return <Text {...props} style={[props.style, styles.error]}></Text>
}

export default ErrorText

const styles = StyleSheet.create({
  error: {
    marginVertical: 8,
    textAlign: 'center',
    color: '#ED4337',
  },
})
