import AsyncStorage from '@react-native-async-storage/async-storage'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View, Text } from 'react-native'

import { RootStackParamList } from '../../App'
import BrandView from '../components/BrandView'
import Card from '../components/Card'
import ClearButton from '../components/ClearButton'
import CodeInput from '../components/CodeInput'
import CopyView from '../components/CopyView'
import ErrorText from '../components/ErrorText'
import { useAuth } from '../hooks'

type Props = NativeStackScreenProps<RootStackParamList, 'ResetPasswordVerify'>

export default function Login({ navigation }: Props) {
  const [code, setCode] = useState('')
  const { verifyResetPassword } = useAuth()
  const { t } = useTranslation()

  useEffect(() => setCode(''), [verifyResetPassword.isError])

  async function submitHandler(value: string) {
    const loginData = await AsyncStorage.getItem('resetLoginData')
    if (!loginData) return;

    if (loginData.match(/\d{8}/)) {
      verifyResetPassword.mutate({ code: value, phone: loginData })
      return
    } else if (loginData.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)) {
      verifyResetPassword.mutate({ code: value, email: loginData })
      return
    }
  }

  return (
    <View style={styles.container}>
      <Card isLoading={verifyResetPassword.isPending}>
        <BrandView />
        <View style={{ alignItems: 'center', marginBottom: 12 }}>
          <Text style={styles.title}>{t('auth.check_email')}</Text>
        </View>

        <CodeInput
          value={code}
          isDisabled={verifyResetPassword.isPending}
          onChange={setCode}
          onFill={submitHandler}
        />
        <ErrorText>{verifyResetPassword.isError && t('auth.error.confirm')}</ErrorText>
        <View
          style={{
            flexBasis: 26,
            marginTop: 12,
            justifyContent: 'center',
          }}
        >
          <ClearButton onPress={() => navigation.navigate('Login')}>
            {t('auth.to_login')}
          </ClearButton>
        </View>
        <CopyView />
      </Card>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#2E2E2E',
    alignItems: 'stretch',
    justifyContent: 'space-around',
  },
  form: {
    marginVertical: 18,
  },
  title: {
    fontSize: 14,
    color: '#FFFFFF',
  },

  borderStyleBase: {
    width: 30,
    height: 45
  },
  borderStyleHighLighted: {
    borderColor: "#03DAC6",
  },
  underlineStyleBase: {
    width: 30,
    height: 45,
    borderWidth: 0,
    borderBottomWidth: 1,
  },
  underlineStyleHighLighted: {
    borderColor: "#03DAC6",
  },
})
