import { useState } from 'react'
import { StyleSheet, Image, View, Modal, Pressable, Text } from 'react-native'
import { RadialGradient } from 'react-native-gradients'
import Card from '../components/Card'
import AsyncStorage from '@react-native-async-storage/async-storage'
import AddressPicker from '../components/AddressPicker'
import GatePicker from '../components/GatePicker'
import { useGates, useRefreshOnFocus, useAddresses } from '../hooks'
import type { Address, Gate } from '../types'
import SpeechBubble from '../components/SpeechBubble'
import { useTranslation } from 'react-i18next'
import LogOut from '../components/LogOut'

export default function Login() {
  const [gate, setGate] = useState<Gate>()
  const [modal, setModal] = useState(false)
  const { open, gates, state } = useGates()
  const { addresses, address, pick } = useAddresses()
  const { t } = useTranslation()

  useRefreshOnFocus(gates.refetch)

  const color =
    state === 'just_opened'
      ? '#6AD267'
      : state === 'error'
      ? '#FF0000'
      : '#D9D9D9'
  const colorList = [
    { offset: '0%', color, opacity: '1' },
    { offset: '100%', color, opacity: '0' },
  ]

  function submitHandler(gate: Gate): void {
    setModal(false)
    setGate(gate)
    open.mutate(gate)
  }

  function addressSelectHandler(address: Address) {
    pick.mutate({ _id: address._id })
    AsyncStorage.setItem('address', address._id)
  }

  return (
    <View style={styles.container}>
      <View style={styles.topRight}>
        <View style={{ margin: 24 }}>
          <LogOut />
        </View>
      </View>
      <Modal
        visible={modal}
        onRequestClose={() => setModal(false)}
        animationType="fade"
        transparent
      >
        <View style={styles.modal}>
          <Pressable
            onPress={() => setModal(false)}
            style={styles.modalCloseArea}
          />
          <Card>
            <View style={styles.padded}>
              {!address ? (
                <AddressPicker
                  addresses={addresses.data}
                  onSelect={addressSelectHandler}
                />
              ) : (
                <GatePicker gates={gates.data} onSelect={submitHandler} />
              )}
            </View>
          </Card>
        </View>
      </Modal>
      <SpeechBubble>
        {address
          ? t(('cat_advices.' + state) as any)
          : t('cat_advices.choose_address')}
      </SpeechBubble>
      <Pressable onPress={() => setModal(true)}>
        <View style={{ width: 250, height: 250, position: 'absolute' }}>
          <RadialGradient
            //@ts-ignore
            x="50%"
            //@ts-ignore
            y="50%"
            //@ts-ignore
            rx="50%"
            //@ts-ignore
            ry="50%"
            colorList={colorList}
          />
        </View>
        <Image
          style={[styles.image, { opacity: state === 'opening' ? 0.3 : 1 }]}
          source={require('../../assets/cat.png')}
        />
      </Pressable>
    </View>
  )
}

const styles = StyleSheet.create({
  topRight: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  image: {
    width: 238,
    height: 273,
  },
  padded: {
    padding: 12,
    paddingTop: 0,
  },
  modalCloseArea: {
    backgroundColor: 'transparent',
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  modal: {
    opacity: 0.9,
    backgroundColor: '#2E2E2E',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    flex: 1,
    backgroundColor: '#2E2E2E',
    alignItems: 'center',
    justifyContent: 'center',
  },
})
