import { StyleSheet, Text, TextStyle } from 'react-native'
import React from 'react'

const PrimaryText = ({
  children,
  style,
}: {
  children: React.ReactNode
  style?: TextStyle
}) => {
  return <Text style={[styles.primary, style]}>{children}</Text>
}

export default PrimaryText

const styles = StyleSheet.create({
  primary: {
    color: '#FFFFFF',
    fontSize: 16,
  },
})
