import { StyleSheet, Text, TextStyle, View } from 'react-native'
import React from 'react'

const SecondaryText = ({
  style,
  children,
}: {
  children: React.ReactNode
  style?: TextStyle
}) => {
  return <Text style={[styles.description, style]}>{children}</Text>
}

export default SecondaryText

const styles = StyleSheet.create({
  description: {
    marginTop: 3,
    fontSize: 12,
    color: '#AAAAAA',
  },
})
