import { useTranslation } from 'react-i18next'
import { View, Text, StyleSheet } from 'react-native'

export default function CopyView() {
  const { t } = useTranslation()
  return (
    <View style={styles.titleContainer}>
      <Text style={styles.description}>{t('brand.copy', { year: new Date().getFullYear() })}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  titleContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  description: {
    marginTop: 3,
    fontSize: 12,
    color: '#AAAAAA',
  },
})
