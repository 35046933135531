import { useState } from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { StyleSheet, View, TextInput, Button } from 'react-native'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { RootStackParamList } from '../../App'
import BrandView from '../components/BrandView'
import Card from '../components/Card'
import ClearButton from '../components/ClearButton'
import CopyView from '../components/CopyView'
import { useAuth } from '../hooks'
import ErrorText from '../components/ErrorText'
import { useTranslation } from 'react-i18next'

type Props = NativeStackScreenProps<RootStackParamList, 'Register'>

export default function Login({ navigation }: Props) {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { t } = useTranslation()
  const [loginTextStyle, setLoginTextStyle] = useState(styles.input)
  // const [password2, setPassword2] = useState('asswecan')

  const { register } = useAuth()

  function submitHandler() {
    if (email.match(/\d{8}/)) {
      register.mutate({ phone: email, name, password })
      return
    } else if (email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)) {
      register.mutate({ email, name, password })
      return
    }
  }

  async function validateLoginTextInput(v: string) {
    setEmail(v)
    if (
      v.match(/\d{8}/) ||
      v.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)
    ) {
      setLoginTextStyle(styles.validLoginInput)
      return
    }
    if (v.length) {
      setLoginTextStyle(styles.invalidLoginInput)
      return
    }
    setLoginTextStyle(styles.input)
  }

  return (
    <View style={styles.container}>
      <Card isLoading={register.isPending}>
        <BrandView />
        <TextInput
          style={styles.input}
          value={name}
          placeholder={t('auth.name')}
          placeholderTextColor="#AAAAAA"
          onChangeText={setName}
          autoCorrect={false}
        />
        <TextInput
          style={[loginTextStyle, { marginTop: 8 }]}
          value={email}
          placeholder={t('auth.email')}
          placeholderTextColor="#AAAAAA"
          onChangeText={setEmail}
          autoCorrect={false}
        />
        <TextInput
          style={[styles.input, { marginTop: 8 }]}
          value={password}
          placeholder={t('auth.password')}
          placeholderTextColor="#AAAAAA"
          onChangeText={setPassword}
          secureTextEntry
          autoCorrect={false}
        />
        <ErrorText>{register.isError && t('auth.error.register')}</ErrorText>
        <Button title={t('auth.sign_up')} onPress={submitHandler} />
        <View
          style={{
            flexBasis: 26,
            marginVertical: 8,
            justifyContent: 'center',
          }}
        >
          <ClearButton onPress={e => navigation.navigate('Login')}>
            {t('auth.to_login')}
          </ClearButton>
        </View>
        <CopyView />
      </Card>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#2E2E2E',
    alignItems: 'stretch',
    justifyContent: 'space-around',
  },
  input: {
    height: 44,
    padding: 12,
    marginVertical: 4,
    borderStyle: 'solid',
    borderWidth: 1.2,
    borderRadius: 5,
    borderColor: '#686868',
    color: '#AAAAAA',
  },
  validLoginInput: {
    height: 44,
    padding: 12,
    marginVertical: 4,
    borderStyle: 'solid',
    borderWidth: 1.2,
    borderRadius: 5,
    borderColor: '#44AA44',
    color: '#AAAAAA',
  },
  invalidLoginInput: {
    height: 44,
    padding: 12,
    marginVertical: 4,
    borderStyle: 'solid',
    borderWidth: 1.2,
    borderRadius: 5,
    borderColor: '#FF0404',
    color: '#AAAAAA',
  },
})
