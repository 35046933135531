import 'stream-browserify';
import React from 'react'
import { StyleSheet, View } from 'react-native'
import { NavigationContainer } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import LoginScreen from './src/screens/LoginScreen'
import RegisterScreen from './src/screens/RegisterScreen'
import ConfirmScreen from './src/screens/ConfirmScreen'
import HomeScreen from './src/screens/HomeScreen'
import ResetPasswordConfirmScreen from './src/screens/ResetPasswordConfirmScreen'
import ResetPasswordVerifyScreen from './src/screens/ResetPasswordVerifyScreen'
import ResetPasswordFormScreen from './src/screens/ResetPasswordFormScreen'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useOnlineManager } from './src/hooks'
import { useTranslation } from 'react-i18next'


import './src/i18n'

export type RootStackParamList = {
  Home: undefined
  Register: undefined
  Confirm: undefined
  Login: undefined
  ResetPasswordConfirm: undefined
  ResetPasswordVerify: undefined
  ResetPasswordForm: undefined
}

const queryClient = new QueryClient()

const Stack = createNativeStackNavigator<RootStackParamList>()

export default function App() {
  useOnlineManager()
  const { t }= useTranslation()
  return (
    <QueryClientProvider client={queryClient}>
      <View style={styles.container}>
        <NavigationContainer>
          <Stack.Navigator>
            <Stack.Screen
              name="Login"
              component={LoginScreen}
              options={{ headerShown: false, title: t('screens.login') + ' | ' + t('brand.title') }}
            />
            <Stack.Screen
              name="Register"
              component={RegisterScreen}
              options={{ headerShown: false, title: t('screens.register') + ' | ' + t('brand.title') }}
            />
            <Stack.Screen
              name="Confirm"
              component={ConfirmScreen}
              options={{ headerShown: false, title: t('screens.confirm') + ' | ' + t('brand.title') }}
            />
            <Stack.Screen
              name="Home"
              component={HomeScreen}
              options={{ headerShown: false, title: t('screens.home') + ' | ' + t('brand.title') }}
            />
            <Stack.Screen
              name="ResetPasswordConfirm"
              component={ResetPasswordConfirmScreen}
              options={{ headerShown: false, title: t('screens.resetPasswordConfirm') + ' | ' + t('brand.title') }}
            />
            <Stack.Screen
              name="ResetPasswordVerify"
              component={ResetPasswordVerifyScreen}
              options={{ headerShown: false, title: t('screens.resetPasswordVerify') + ' | ' + t('brand.title') }}
            />
            <Stack.Screen
              name="ResetPasswordForm"
              component={ResetPasswordFormScreen}
              options={{ headerShown: false, title: t('screens.resetPasswordForm') + ' | ' + t('brand.title') }}
            />
          </Stack.Navigator>
        </NavigationContainer>
      </View>
    </QueryClientProvider>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#2E2E2E',
    alignItems: 'stretch',
    justifyContent: 'space-around',
  },
})
