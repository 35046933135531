import AsyncStorage from '@react-native-async-storage/async-storage'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View, TextInput, Button } from 'react-native'
import { RootStackParamList } from '../../App'
import BrandView from '../components/BrandView'
import Card from '../components/Card'
import ClearButton from '../components/ClearButton'
import CopyView from '../components/CopyView'
import ErrorText from '../components/ErrorText'
import { useAuth } from '../hooks'

type Props = NativeStackScreenProps<RootStackParamList, 'ResetPasswordConfirm'>

export default function Login({ navigation }: Props) {
  const [password, setPassword] = useState('')
  const [passwordRepeat, setPasswordRepeat] = useState('')
  const { confirmResetPassword, refreshToken } = useAuth()
  const [passwordTextInputStyle, setPasswordTextInputStyle] = useState(styles.input)
  const [passwordRepeatTextInputStyle, setPasswordRepeatTextInputStyle] = useState(styles.input)


  const { t } = useTranslation()

  async function submitHandler() {
    if (passwordRepeat != password || password.length < 6) return
    const resetToken = await AsyncStorage.getItem('passwordResetToken')
    const loginData = await AsyncStorage.getItem('resetLoginData')
    if (!loginData || !resetToken) return
    if (loginData.match(/\d{8}/)) {
      confirmResetPassword.mutate({ resetToken, phone: loginData, password })
      return
    } else if (loginData.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)) {
      confirmResetPassword.mutate({ resetToken, email: loginData, password })
      return
    }
  }

  async function validatePasswordTextInput(v: string) {
    setPassword(v)
    if (v.length > 5) {
      setPasswordTextInputStyle(styles.validLoginInput)
      return
    }
    if (v.length) {
      setPasswordTextInputStyle(styles.invalidLoginInput)
      return
    }
    setPasswordTextInputStyle(styles.input)
  }

  async function validatePasswordRepeatTextInput(v: string) {
    setPasswordRepeat(v)
    if (v.length) {
      setPasswordRepeatTextInputStyle(password == v ? styles.validLoginInput : styles.invalidLoginInput)
      return
    }
    setPasswordRepeatTextInputStyle(styles.input)
  }

  return (
    <View style={styles.container}>
      <Card isLoading={confirmResetPassword.isPending || refreshToken.isPending}>
        <BrandView />
        <View style={styles.form}>
          <TextInput
            style={passwordTextInputStyle}
            value={password}
            placeholder={t('auth.password')}
            placeholderTextColor="#AAAAAA"
            secureTextEntry
            autoCorrect={false}
            onChangeText={validatePasswordTextInput}
          />
          <TextInput
            style={passwordRepeatTextInputStyle}
            value={passwordRepeat}
            placeholder={t('auth.password')}
            placeholderTextColor="#AAAAAA"
            secureTextEntry
            autoCorrect={false}
            onChangeText={validatePasswordRepeatTextInput}
          />
          <ErrorText>{confirmResetPassword.isError && t('auth.error.somethingWentWrong')}</ErrorText>
          <Button title={t('auth.reset_password')} onPress={submitHandler} />
          <View
            style={{
              flexBasis: 26,
              marginTop: 28,
              marginBottom: 12,
              justifyContent: 'center',
            }}
          >
            <ClearButton onPress={() => navigation.navigate('Login')}>
              {t('auth.back')}
            </ClearButton>
          </View>
        </View>
        <CopyView />
      </Card>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#2E2E2E',
    alignItems: 'stretch',
    justifyContent: 'space-around',
  },
  form: {
    marginVertical: 18,
  },
  input: {
    height: 44,
    padding: 12,
    marginVertical: 4,
    borderStyle: 'solid',
    borderWidth: 1.2,
    borderRadius: 5,
    borderColor: '#686868',
    color: '#AAAAAA',
  },
  validLoginInput: {
    height: 44,
    padding: 12,
    marginVertical: 4,
    borderStyle: 'solid',
    borderWidth: 1.2,
    borderRadius: 5,
    borderColor: '#44AA44',
    color: '#AAAAAA',
  },
  invalidLoginInput: {
    height: 44,
    padding: 12,
    marginVertical: 4,
    borderStyle: 'solid',
    borderWidth: 1.2,
    borderRadius: 5,
    borderColor: '#FF0404',
    color: '#AAAAAA',
  },
})
