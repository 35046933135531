import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import enUS from './locales/en-US.json'
import mnMN from './locales/mn-MN.json'

import 'intl-pluralrules'

const resources = {
  en: enUS,
  mn: mnMN,
}

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'mn',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
