import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useState } from 'react'
import { Gate } from '../types'
import { client } from './useAuth'

async function getGates() {
  const res = await client.get<{ items: Gate[]; page: number; total: number }>(
    '/devices'
  )
  return res.data.items
}

type CommandState = 'idle' | 'opening' | 'just_opened' | 'error' | 'forbidden'

export function useGates() {
  const [state, setState] = useState<CommandState>('idle')
  const queryClient = useQueryClient()
  const gates = useQuery<Gate[]>({
    queryKey: ['gates'],
    queryFn: getGates,
    initialData: [],
  })

  const open = useMutation({
    mutationKey: ['gates', 'open'],
    async mutationFn(gate: Gate) {
      const res = await client.post('/devices/control/' + gate._id, {
        command: 'OPEN',
      })
      return res.data
    },
    onError(error) {
      if (axios.isAxiosError(error) && error.status === 403) {
        setState('forbidden')
        return
      }
      setState('error')
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['gates'] })
      setState('just_opened')
    },
    onSettled() {
      setTimeout(() => {
        setState('idle')
      }, 5000)
    },
  })

  return {
    open,
    state,
    gates,
  }
}
