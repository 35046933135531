import React, { useState } from 'react'
import { Text, Pressable, GestureResponderEvent } from 'react-native'

export default function ClearButton({
  children,
  onPress,
}: {
  children: React.ReactNode
  onPress(event: GestureResponderEvent): void
}) {
  const [opacity, setOpacity] = useState(1)
  return (
    <Pressable
      onPress={onPress}
      onPressIn={() => setOpacity(0.8)}
      onPressOut={() => setOpacity(1)}
    >
      <Text
        style={{
          opacity: opacity,
          color: '#2776ED',
          fontWeight: '600',
          textAlign: 'center',
        }}
      >
        {children}
      </Text>
    </Pressable>
  )
}
